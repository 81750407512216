import React from "react";
import formsConfig from "../../../formsConfig.json";
import OTOForm from "../../components/OTOForm";
import addIDSToSchema from "../../util/addIDSToSchema";

const formId = "Travel expense authorization";
const _schema = typeof formsConfig[formId] !== "undefined" ? formsConfig[formId] : null;

function FormPage() {
  if (!_schema) return <h1>ERROR</h1>;
  let schema = addIDSToSchema(_schema);
  return <OTOForm title={formId} schema={schema.schema} fields_object={schema.object} />;
}

export default FormPage;
